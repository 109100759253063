/**
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */


import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "button", "password", "showPassword" ];

  connect() {
    this.buttonTarget.disabled = true;
    this.setPasswordVisibility();
    // In case the browser beat us to it (e.g. Firefox Password Manager)
    this.check();
  }

  setPasswordVisibility() {
    this.passwordTarget.type = this.showPasswordTarget.checked ? "text" : "password";
  }

  check() {
    this.buttonTarget.disabled = this.passwordTarget.value.trim() === "";
  }
}
